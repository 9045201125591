import { Injectable } from '@angular/core';
import { AssignedLicense, PasswordProfile, User } from '@microsoft/microsoft-graph-types-beta';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { combineLatest, EMPTY, forkJoin, Observable, of, tap, catchError, concatMap, expand, map, mergeMap, reduce, switchMap, filter, distinct, take } from 'rxjs';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import { ChangesService } from 'src/app/services/changes.service';
import { storeChangesToDB } from '../../octiga/changes/actions';
import { addDirectoryRoleMemberSuccess } from '../directoryRoles/members/actions';
import * as actions from './user.actions';
import { client } from '../..';
import { loadSubscribedSkus } from '../subscribedSkus/actions';

interface GraphUsersResponse {
    value: User[]
    '@odata.nextLink'?: string
}


function parseToken(response: GraphUsersResponse): string | false {
    let skiptoken: string | false = false;
    if (response['@odata.nextLink']) {
        skiptoken = response['@odata.nextLink'].split('skiptoken=')[1];
    }
    return skiptoken;
}

@Injectable()
export class GraphUserEffects {
    private fetchSignInActivityWithPaging(tenant: string): Observable<User[]> {
        return this.ajax.get(tenant, '/api/microsoft/graph/users?$select=id,signInActivity,lastPasswordChangeDateTime')
            .pipe(
                expand((data: GraphUsersResponse) => {
                    const token = parseToken(data);
                    if (token) {
                        return this.ajax.get(tenant, `/api/microsoft/graph/users?$skiptoken=${token}&$select=id,signInActivity,lastPasswordChangeDateTime`);
                    } else {
                        return EMPTY;
                    }
                }),
                reduce((acc, data: any) => acc.concat(data.value), []),
                catchError(() => {
                    return of([]);
                })
            );

    }

    private fetchUsersWithPaging(tenant: string): Observable<User[]> {
        return this.ajax.get(tenant, '/api/microsoft/graph/users')
            .pipe(
                expand((data: GraphUsersResponse) => {
                    const token = parseToken(data);
                    if (token) {
                        return this.ajax.get(tenant, `/api/microsoft/graph/users?$skiptoken=${token}`);
                    } else {
                        return EMPTY;
                    }
                }),
                reduce((acc, data: any) => acc.concat(data.value), [])
            );
    }

    private putPassword(tenant: string, userPrincipalName: string, passwordProfile: PasswordProfile) {
        return this.ajax.patch(tenant, `/api/microsoft/graph/users/${userPrincipalName}`, { passwordProfile });
    }

    private revokeSession(tenant: string, userPrincipalName: string) {
        return this.ajax.post(tenant, `/api/microsoft/graph/users/${userPrincipalName}/revokeSignInSessions`);
    }

    loadUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadGraphUsers),
            distinct(action => action._tenant),
            switchMap(action => this.store.pipe(
                select(client(action._tenant).graph.users.status),
                filter(status => !status.loaded),
                map(() => action),
                take(1)
            )),
            mergeMap(({ _tenant }) => forkJoin([
                this.fetchUsersWithPaging(_tenant),
                this.fetchSignInActivityWithPaging(_tenant)
            ])
                .pipe(
                    map(([users, signIns]) => {
                        const mapped = new Map(signIns.map(item => [item.id, item]));
                        const merged = users.map(user => ({
                            ...user,
                            ...mapped.get(user.id)
                        }));
                        return actions.loadGraphUsersSuccess({ _tenant, users: merged });
                    }),
                    catchError((error) => of(actions.loadGraphUsersFailure({ _tenant, error })))
                ))
        )
    );

    reloadUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.reloadGraphUsers),
            mergeMap(({ _tenant }) => forkJoin([
                this.fetchUsersWithPaging(_tenant),
                this.fetchSignInActivityWithPaging(_tenant)
            ])
                .pipe(
                    map(([users, signIns]) => {
                        const mapped = new Map(signIns.map(item => [item.id, item.signInActivity]));
                        const merged = users.map(user => ({ ...user, signInActivity: mapped.get(user.id) }));
                        return actions.loadGraphUsersSuccess({ _tenant, users: merged });
                    }),
                    catchError((error) => of(actions.loadGraphUsersFailure({ _tenant, error })))
                ))
        )
    );


    resetUserPassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.resetUserPassword),
            mergeMap(({ _tenant, userPrincipalName, passwordProfile }) => this.putPassword(_tenant, userPrincipalName, passwordProfile)
                .pipe(
                    concatMap(() => {
                        const params = { user: userPrincipalName };
                        const item = this.changesService.formatChangesObjectToDB(params, 'reset-password');
                        return [
                            actions.resetUserPasswordSuccess({ _tenant, userPrincipalName }),
                            storeChangesToDB({ _tenant, item }),
                            actions.revokeUserSignInSession({ _tenant, userPrincipalName })
                        ];
                    }),
                    catchError((error) => of(actions.resetUserPasswordFailure({ _tenant, userPrincipalName, error })))
                )
            )
        )
    );

    revokeUserSession$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.revokeUserSignInSession),
            mergeMap(({ _tenant, userPrincipalName }) => this.revokeSession(_tenant, userPrincipalName)
                .pipe(
                    map(() => actions.revokeUserSignInSessionSuccess({ _tenant, userPrincipalName })),
                    catchError((error) => of(actions.revokeUserSignInSessionFailure({ _tenant, userPrincipalName, error })))
                )
            )
        )
    );

    deleteUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteGraphUsers),
            mergeMap(({ _tenant, ids }) => this.deleteGraphUsers(_tenant, ids)
                .pipe(
                    map(() => actions.deleteGraphUsersSuccess({ _tenant, ids })),
                    catchError((error) => of(actions.deleteGraphUsersFailure({ _tenant, error })))
                )
            )
        )
    );


    createUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.createGraphUser),
            mergeMap(({ _tenant, user }) => this.createGraphUser(_tenant, user)
                .pipe(
                    map((user) => actions.createGraphUsersSuccess({ _tenant, user })),
                    catchError((error) => of(actions.createGraphUsersFailure({ _tenant, error })))
                )
            )
        )
    );

    createUsersWithRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.createGraphUsersWithRole),
            mergeMap(({ _tenant, users, roleTemplateId }) => this.createGraphUsersWithRole(_tenant, users, roleTemplateId)
                .pipe(
                    tap((new_users) => {
                        new_users.forEach(new_user => {
                            this.store.dispatch(addDirectoryRoleMemberSuccess({ _tenant, roleTemplateId, memberId: new_user.id }));
                        });
                    }),
                    map((new_users) => actions.createGraphUsersWithRoleSuccess({ _tenant, users: new_users })),
                    catchError((error) => of(actions.createGraphUsersFailure({ _tenant, error })))
                )
            )
        )
    );

    assignLicense$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.assignLicense),
            mergeMap(({ _tenant, userId, addLicenses, removeLicenses }) => this.assignLicense(_tenant, userId, addLicenses, removeLicenses)
                .pipe(
                    tap(user => this.store.dispatch(loadSubscribedSkus({ _tenant }))),
                    map((user) => actions.assignLicenseSuccess({ _tenant, user })),
                    catchError((error) => of(actions.assignLicenseFailure({ _tenant, error }))),
                )
            )
        )
    );



    updateGraphUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateGraphUser),
            mergeMap(({ _tenant, userId, data }) => this.patch(_tenant, userId, data)
                .pipe(
                    map(() => actions.updateGraphUserSuccess({ _tenant, userId, data })),
                    catchError((error) => of(actions.updateGraphUserFailure({ _tenant, error }))),
                )
            )
        )
    );

    private patch(_tenant: string, userId: string, data: Partial<User>) {
        return this.ajax.patch<Partial<User>>(_tenant, `/api/microsoft/graph/users/${userId}`, data);
    }

    private assignLicense(tenant: string, userId: string, addLicenses: Array<AssignedLicense>, removeLicenses: Array<string>) {
        const body = {
            addLicenses: addLicenses,
            removeLicenses: removeLicenses
        };

        return this.ajax.post<Partial<User>>(tenant, `/api/microsoft/graph/users/${userId}/assignLicense`, body);
    }





    createGraphUsersWithRole(_tenant: string, users: Array<Partial<User>>, roleTemplateId): Observable<User[]> {
        const users$ = combineLatest([...users.map(user => this.createGraphUser(_tenant, user).pipe(switchMap(new_user => this.assignRole(_tenant, new_user.id, roleTemplateId).pipe(map(res => new_user)))))]);
        return users$;
    }


    assignRole(_tenant: string, userId: string, roleTemplateId: string) {
        const directoryObject = {
            '@odata.id': `https://graph.microsoft.com/beta/users/${userId}`
        };

        return this.ajax.post(_tenant, `/api/microsoft/graph/directoryRoles/roleTemplateId=${roleTemplateId}/members/$ref`, directoryObject);
    }

    createGraphUser(_tenant: string, user: Partial<User>): Observable<User> {
        return this.ajax.post<User>(_tenant, '/api/microsoft/graph/users', user);
    }


    private deleteGraphUsers(tenant: string, ids: string[]) {
        return combineLatest(ids.map(id => this.ajax.delete(tenant, `/api/microsoft/graph/users/${id}`)));
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private store: Store<any>,
        private changesService: ChangesService
    ) { }
}
